import React from 'react';
import SectionHeader from '../../components/SectionHeader'

function Drawing() {
  return (
    <SectionHeader
    title="Customer Reviews"
    description="Explore our featured cards collection."
  />
  )
}

export default Drawing